import { Link } from "gatsby"
import React, { useEffect, useState } from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import FoodTruck from "../images/food-truck2.jpg"
import Bg1 from "../images/tourplan_bg1.jpg"
import Bg2 from "../images/tourplan_bg2.jpg"
import Bg3 from "../images/tourplan_bg3.jpg"
import Bg5 from "../images/tourplan_bg5.jpg"
import Bg6 from "../images/tourplan_bg6.jpg"
import Bg7 from "../images/tourplan_bg7.jpg"
import Bg8 from "../images/tourplan_bg8.jpg"
import html2canvas from "html2canvas"
import { useForm } from "react-hook-form"

import GBold from "../images/Gotham-Bold.ttf"
import GRegular from "../images/Gotham-Regular.ttf"

import "../scss/image.scss"

import Standorte from "../standorte.json"

export default function Banner() {
  const { register, handleSubmit, watch, errors, setValue } = useForm()
  const bgImage = watch("bg")
  const text = watch("text")
  const tpos = watch("text-position")
  const ipos = watch("image-position")
  const [url, setUrl] = useState()

  const onSubmit = data => {
    document.getElementById("banner").style.position = "absolute"
    document.querySelector("body").style.overflow = "hidden"

    html2canvas(document.getElementById("banner"), {
      width: 900,
      height: 900,
      scrollX: -0.5,
      scrollY: -window.scrollY,
    }).then(canvas => {
      document.getElementById("banner").style.position = ""
      document.querySelector("body").style.overflow = ""
      let a = document.createElement("a")
      a.href = canvas.toDataURL("image/jpeg", 1.0)
      a.download = "image.jpg"
      a.click()
    })
  }

  const style = `
    @font-face {
      font-family: 'GothamRegular';
      src: url('${GRegular}')  format('truetype')
    }

    @font-face {
      font-family: 'GothamBold';
      src: url('${GBold}')  format('truetype')
    }
  `

  return (
    <Layout>
      <SEO title="Banner" />
      <style>{style}</style>
      <div className="bg-light">
        <div className="row align-items-center justify-content-center">
          <div className="col-12 text-center">
            <div className="card my-3 banner-form" style={{ width: "40rem" }}>
              <div className="card-body text-left">
                <form onSubmit={handleSubmit(onSubmit)}>
                  <div className="form-group mb-3 row">
                    <label className="form-label col-3 col-form-label">
                      Image
                    </label>
                    <div className="col-9">
                      <input
                        accept="image/*"
                        type="file"
                        onChange={e =>
                          setUrl(URL.createObjectURL(e.target.files[0]))
                        }
                      />
                    </div>
                    <label className="form-label col-3 col-form-label">
                      Text
                    </label>
                    <div className="col-9">
                      <textarea
                        name="text"
                        ref={register}
                        placeholder="Text"
                        className="form-control"
                      />
                    </div>

                    <label className="form-label col-3 col-form-label">
                      Image Position
                    </label>
                    <div className="col-9">
                      <input
                        className="form-control"
                        type="range"
                        min="0"
                        max="100"
                        ref={register}
                        name="image-position"
                      />
                    </div>

                    <label className="form-label col-3 col-form-label">
                      Text Position
                    </label>
                    <div className="col-9">
                      <input
                        className="form-control"
                        type="range"
                        min="0"
                        max="100"
                        ref={register}
                        name="text-position"
                      />
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
        <div
          style={{
            backgroundImage: `url(${url})`,
            backgroundPositionY: `${ipos}%`,
          }}
          className="image_generator"
        >
          <div className="image_border_1">
            <div className="image_border_2">
              <span
                className="title"
                style={{ marginTop: (900 * (tpos || 0)) / 100 }}
              >
                <span className="title_text">{text}</span>
              </span>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}
